<template>
    <a-card title="">
        <div class="heatmap-box">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <div class="heatmap-box-title">
                <span>授课情况</span>
                <span style='float: right;'>
                    <a-select :value="year" @change="handleChange">
                        <a-select-option v-for="(d, index) of yearList" :key="index" :value="d">{{ d }}</a-select-option>
                    </a-select>
                </span>
            </div>
            <calendar-heatmap ref='CalendarHeatmap' :max="50" :locale='local' tooltip-unit="次授课" :range-color="['ebedf0', '#D6E685', '#8CC665', '#44A340', '#1E6823']" :values='list' :end-date="endDate" />
            <div class="heatmap-box-dec">
                <span>最近一年授课: {{desc.contributions}}次</span>
                <span>最长连续授课: {{desc.max_continuous}}天</span>
                <span>最近连续授课: {{desc.recent_contributions}}天</span>
            </div>
        </div>
    </a-card>
</template>

<script>
    import { CalendarHeatmap } from 'vue-calendar-heatmap'
    import moment from 'moment'
    import 'vue2-datepicker/index.css'
    export default {
        name:'heatmap',
        components: {
            CalendarHeatmap,
        },
        data() {
            return {
                loading:false,
                seach:{
                    // start_date:moment().subtract(1, "years").subtract(3, "days").format('YYYY-M-D'),
                    start_date:moment().subtract(1, "years").format('YYYY-M-D'),
                    end_date:moment().add(7, 'days').format('YYYY-M-D'),
                    filter_type:'day'
                },
                list:[],
                local:{
                    months: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
                    days: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
                    on: '在',
                    less: '少',
                    more: '多'
                },
                desc:'',
                year: '',
                yearList:[],
                endDate:moment().add(7, 'days').format('YYYY-M-D')
            }
        },
        created () {
            this.seach.is_sub = this.is_sub
            let defaultYear = 2017
            let nowYear = Number(moment().format('YYYY'))
            let yearList = [nowYear]
            for(let i=0; i <= nowYear-defaultYear; i++){
                nowYear-=1
                yearList.push(nowYear)
            }
            this.yearList = yearList
            this.year = Number(moment().format('YYYY'))
            this.getList()
        },
        methods: {
            moment,
            async getList() {
                this.loading = true
                let res = await this.$store.dispatch('scheduleHeatmapAction', {search:this.seach})
                this.list = res.data.heatmap
                this.desc = res._data
                this.loading = false
            },
            handleChange(e){
                this.year = e
                let time = `${e}-${moment().format('M-D')}`
                let nowYear = moment().format("YYYY")
                if(e == nowYear){
                    this.endDate = moment().add(7, 'days').format('YYYY-M-D')
                    this.seach.end_date = moment().add(7, 'days').format('YYYY-M-D')
                    this.seach.start_date = moment().subtract(1, "years").format('YYYY-M-D')
                }else{
                    this.endDate = moment(time).endOf('year').format('YYYY-M-D')
                    var SpecialTo = moment(time).endOf('year').add(7, 'days').format("YYYY-MM-DD");
                    this.seach.end_date = SpecialTo
                    this.seach.start_date = moment(time).endOf('year').subtract(1, "years").subtract(7, 'days').format('YYYY-M-D')
                }
                this.getList()
            }
        },
    }
</script>

<style lang="scss">
    .heatmap-box{
        height:400px;
    }
    .heatmap-box-title{
        margin-bottom: 30px;
    }
    .heatmap-box-dec span{
        margin-right:10px;
        color: #767676;
    }
</style>