<template>
    <a-card title="">
        <div style="height:400px">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <div>
                <ve-histogram
                v-if="!loading"
                judge-width
                :legend="{show:false}"
                :data="list" :mark-line="markLine" :grid="{right: '5%'}" 
                    :toolbox="toolbox"
                    height="360px"
                    :title="{
                        text:'常规课时消课趋势',
                        subtext: ``,
                        textStyle: {
                            fontSize: 15,
                        },
                        subtextStyle: {
                            fontSize: 12.5
                        }
                    }" 
                    :extend="{
                    series:{
                    type:'bar',
                    barMaxWidth: 10,
                    itemStyle:{
                        normal:{
                            color: function(params) {
                                return {
                                    type: 'linear',
                                    x:1,
                                    y:1,
                                    x2:0,
                                    y2:0,
                                    colorStops: [{
                                        offset: 0,
                                        color: '#ffffff'
                                        
                                    }, {
                                        offset: 1,                    
                                        color: '#00cca2'
                                    }],
                                }
                            },
                            barBorderRadius:[30,30,0,0]
                        }
                    }
            },
            dataZoom:[{type:'inside'}]}" :settings="{yAxisType: ['KMB', 'KMB'],label: { show: true, position: 'top'}}"></ve-histogram>
            </div>
        </div>
    </a-card>
</template>
<script>
export default {
    name:'trend',
    props:['is_sub'],
    data(){
        this.markLine = {
        symbol: ['none', 'none'], //去掉箭头
        data: [
          {
            name: '平均线',
            type: 'average'
          }
        ]
      },
      this.toolbox = {
        feature: {
          magicType: {},
          saveAsImage: {pixelRatio:2}
        }
      },
      this.colors = ['#ec4758']
        return{
            loading:false,
            list:{},
            seach:{},
        }
    },
    created(){
        this.seach.is_sub = this.is_sub
        this.getList()
    },
    methods:{
        async getList() {
            this.loading = true
            let res = await this.$store.dispatch('scheduleTeachStatisAction', {search:this.seach})
            this.list = res.data
            this.loading = false
        },
    },
    watch: {
        is_sub(newValue){
            this.seach.is_sub = newValue
            this.getList()
        }
    },
}
</script>