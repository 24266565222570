<template>
    <div class="zcxy">
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>教务中心</a-breadcrumb-item>
            <a-breadcrumb-item>教务简报</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="search" style="margin-left:20px">
            <a-form layout='inline'>
            <a-form-item>
                <a-tooltip placement="topLeft" >
                <a-select :value='is_sub' @change='handleIsSub' style="width: 100px">
                    <a-select-option :value="0">我的</a-select-option>
                    <a-select-option :value="1">我下属的</a-select-option>
                </a-select>
                </a-tooltip>
            </a-form-item>
            <!-- <a-form-item>
                <a-button type="primary" @click="searchList">搜索</a-button>
            </a-form-item> -->
            </a-form>
        </div>
        <div class="dashboard bk-group" style="padding:20px 20px 0 20px">
            <a-row class="mb16" :gutter="16">
                <a-col :lg="24" :xl="12">
                    <heatmap />
                </a-col>
                <a-col :lg="24" :xl="12">
                    <tercher :is_sub='is_sub' />
                </a-col>
            </a-row>
        </div>
    </div>
</template>

<script>
    import heatmap from './heatmap'
    import tercher from './tercher'
    export default {
        name:'briefing',
        data() {
            return {
                is_sub:0,
            }
        },
        components: {
            heatmap,
            tercher
        },
        create () {
            if(!this.$ls.get('is_sub')){
                this.$ls.set('is_sub', 0)
            }else{
                this.is_sub = this.$ls.get('is_sub')
            }
        },
        methods:{
            handleIsSub(e){
                this.$ls.set('is_sub', e)
                this.is_sub = e
            }
        }
    }
</script>

<style lang="scss">

</style>