var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{attrs:{"title":""}},[_c('div',{staticStyle:{"height":"400px"}},[_c('vue-element-loading',{attrs:{"active":_vm.loading,"color":"#00cca2","spinner":"spinner"}}),_c('div',[(!_vm.loading)?_c('ve-histogram',{attrs:{"judge-width":"","legend":{show:false},"data":_vm.list,"mark-line":_vm.markLine,"grid":{right: '5%'},"toolbox":_vm.toolbox,"height":"360px","title":{
                    text:'常规课时消课趋势',
                    subtext: "",
                    textStyle: {
                        fontSize: 15,
                    },
                    subtextStyle: {
                        fontSize: 12.5
                    }
                },"extend":{
                series:{
                type:'bar',
                barMaxWidth: 10,
                itemStyle:{
                    normal:{
                        color: function(params) {
                            return {
                                type: 'linear',
                                x:1,
                                y:1,
                                x2:0,
                                y2:0,
                                colorStops: [{
                                    offset: 0,
                                    color: '#ffffff'
                                    
                                }, {
                                    offset: 1,                    
                                    color: '#00cca2'
                                }],
                            }
                        },
                        barBorderRadius:[30,30,0,0]
                    }
                }
        },
        dataZoom:[{type:'inside'}]},"settings":{yAxisType: ['KMB', 'KMB'],label: { show: true, position: 'top'}}}}):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }